<template>
  <div class="editpage-wrapper">
    <MobileHbPreview v-if="showPreview&&!onlyMiddle" :hb="hb" :audioInfo="audioInfo" />
    <div class="editpage-stuff-with-no-preview" v-if="hasStuff"></div>
    <div class="editpage-content" :class="{'conten-col2':onlyMiddle}">
      <slot name="middle">
        <BaseInfoPanel
          :hb="hb"
          :editable="baseInfoEditalbe"
          :showConfig="baseInfoConfigEditable"
          ref="baseInfo"
        />
        <slot></slot>
        <!-- 1 -->
        <div class="edit_base">
          <template v-if="!locked">
            <template v-if="showTags">
              <template v-if="hb.tagMap&&[0,1,2,3,6].includes(roleCode)">
                <span v-if="roleCode===6">编辑标签：</span>
                <TagsInput class="m-b-md" :tags="hb.tagMap[onlyMode?0:currentIndex]" />
              </template>
              <template v-if="hb.imageTag&&[4,6].includes(roleCode)">
                <span v-if="roleCode===6">插画标签：</span>
                <TagsInput class="m-b-md" :tags="hb.imageTag[onlyMode?0:currentIndex]" />
              </template>
              <template v-if="hb.audioTag&&[5,6].includes(roleCode)">
                <span v-if="roleCode===6">音频标签：</span>
                <TagsInput class="m-b-md" :tags="hb.audioTag[onlyMode?0:currentIndex]" />
              </template>
            </template>
            <Input type="textarea" placeholder="全文备注" :rows="4" v-model="hb.comment" />
            <Input
              v-if="hasIntroduction"
              class="m-t-sm"
              type="textarea"
              placeholder="编辑文字简介（共用当前所有篇）"
              :rows="4"
              v-model="hb.introduction"
            />
          </template>
          <slot name="buttons"></slot>
          <!-- 2 -->
        </div>
      </slot>
    </div>
    <div class="editpage-right" v-if="!onlyMiddle">
      <slot name="right-side"></slot>
    </div>
    <div class="editpage-stuff-with-no-preview" v-if="hasStuff"></div>
  </div>
</template>

<script>
export default {
  props: {
    hb: {
      required: true
    },
    showPreview: {
      default: true
    },
    audioInfo: {},
    onlyMiddle: {
      default: false
    },
    showTags: {
      default: true
    },
    locked: {
      default: false
    }
  },
  computed: {
    hasStuff() {
      return !this.showPreview || this.onlyMiddle;
    },
    roleCode() {
      return this.$store.getters.getRoleCode;
    },
    onlyMode() {
      return /Verse/.test(this.hb.contentType);
    },
    baseInfoEditalbe() {
      // 编辑，123审才可以修改基本信息（如标题，分类等）
      return [0, 1, 2, 3, 4, 5, 6].includes(this.roleCode);
    },
    baseInfoConfigEditable() {
      // 只有编辑才可以修改文章的听读配置等
      return [0].includes(this.roleCode);
    },
    hasIntroduction() {
      return  /^\w{2}(?:Idiom|Verse|View|Sentence)$/.test(this.hb.contentType); // false; // 
    }
  },
  methods: {
    validateBaseInfo() {
      if (!this.hb.title) {
        return '主标题不能为空!';
      }
      if (!this.hb.ageType) {
        return '年龄段不能为空!';
      }
      const { secondTypes, thirdTypes } = this.$refs.baseInfo;
      if (secondTypes && secondTypes.length) {
        if (!this.hb.category) {
          return '二级分类不能为空!';
        }
        if (thirdTypes && thirdTypes.length && !this.hb.secondCategory) {
          return '三级分类不能为空!';
        }
      }
    }
  }
};
</script>

<style scoped>
.editpage-wrapper {
  background: #f2f2f2;
  /* #e3e8ee */
  padding-bottom: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  min-width: 1850px;
  min-height: calc(100vh - 60px);
  width: 100vw;
}

.editpage-content,
.editpage-right {
  min-height: 800px;
  height: max-content;
  border: 1px solid gainsboro;
  background: #fff;
}

.editpage-content.conten-col2 {
  width: 1250px;
}

.editpage-content {
  width: 670px;
  padding: 1.5em 2.2em;
  border-radius: 10px;
}

.editpage-right {
  width: 600px;
  padding-bottom: 30px;
}

.editpage-stuff-with-no-preview {
  width: 200px;
}
.edit_base {
  margin: 30px 0;
}
</style>